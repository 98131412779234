import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "@firebolt-dev/jsx/jsx-runtime";
import {cloneElement} from 'react';
import {useMDXComponents} from 'firebolt';
const MDXLayout = function MDXWrapper({children}) {
  const components = useMDXComponents();
  return cloneElement(children, {
    components
  });
};
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h1: "h1",
    h2: "h2",
    li: "li",
    p: "p",
    pre: "pre",
    span: "span",
    table: "table",
    tbody: "tbody",
    td: "td",
    th: "th",
    thead: "thead",
    tr: "tr",
    ul: "ul",
    ...props.components
  }, {Meta} = _components;
  if (!Meta) _missingMdxReference("Meta", true);
  return _jsxs(_Fragment, {
    children: [_jsx(Meta, {
      title: "Reference: useCookie",
      description: "API Reference for the useCookie hook in Firebolt."
    }), "\n", _jsx(_components.h1, {
      children: "useCookie"
    }), "\n", _jsxs(_components.p, {
      children: ["The ", _jsx(_components.code, {
        children: "useCookie"
      }), " hook provides the value of a cookie and a setter to update it, much like Reacts ", _jsx(_components.code, {
        children: "useState"
      }), " hook."]
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "const"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " ["
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: "value"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ", "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: "setValue"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "] "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " useCookie"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "("
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "'theme'"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ", "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "'system'"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ")"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsxs(_components.p, {
      children: ["The 1st argument to ", _jsx(_components.code, {
        children: "useCookie"
      }), " is the cookie ", _jsx(_components.code, {
        children: "key"
      }), " and the 2nd argument is an optional default value."]
    }), "\n", _jsx(_components.p, {
      children: "Returns an array with exactly two values:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "The current value of the cookie"
      }), "\n", _jsxs(_components.li, {
        children: ["The ", _jsx(_components.code, {
          children: "set"
        }), " function that lets you update the value of the cookie."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      children: "set(value|fn, options)"
    }), "\n", _jsx(_components.p, {
      children: "The set function returned by useCookie lets you update the cookie to a different value and trigger a re-render. You can pass the next value directly, or a function that calculates it from the previous value:"
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "setTheme"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "("
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "'dark'"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ")"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "setNumber"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "("
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#E36209",
              "--shiki-dark": "#FFAB70"
            },
            children: "n"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " =>"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " n "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "+"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: " 1"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ")"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsx(_components.h2, {
      children: "Synchronization"
    }), "\n", _jsxs(_components.p, {
      children: ["Updating a cookie will also update any other components observing its value. This also works when updating cookies on the server using the ", _jsx(_components.a, {
        href: "/docs/ref/context",
        children: "Context"
      }), " object."]
    }), "\n", _jsx(_components.h2, {
      children: "Options"
    }), "\n", _jsxs(_components.p, {
      children: ["By default cookies use session storage but you can change these per-cookie by providing an additional options object, or globally in your ", _jsx(_components.a, {
        href: "/docs/ref/config",
        children: "firebolt.config.js"
      }), " file."]
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "setValue"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "(authToken, { expires: "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: "30"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " })"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsx(_components.p, {
      children: "Available cookie options are:"
    }), "\n", _jsxs(_components.table, {
      children: [_jsx(_components.thead, {
        children: _jsxs(_components.tr, {
          children: [_jsx(_components.th, {
            children: "Key"
          }), _jsx(_components.th, {
            children: "Type"
          }), _jsx(_components.th, {
            children: "Default"
          }), _jsx(_components.th, {
            children: "Note"
          })]
        })
      }), _jsxs(_components.tbody, {
        children: [_jsxs(_components.tr, {
          children: [_jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "expires"
            })
          }), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "Numer|Date"
            })
          }), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "null"
            })
          }), _jsx(_components.td, {
            children: "Numbers are treated as days"
          })]
        }), _jsxs(_components.tr, {
          children: [_jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "path"
            })
          }), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "String"
            })
          }), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "/"
            })
          }), _jsx(_components.td, {})]
        }), _jsxs(_components.tr, {
          children: [_jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "domain"
            })
          }), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "String"
            })
          }), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "null"
            })
          }), _jsx(_components.td, {})]
        }), _jsxs(_components.tr, {
          children: [_jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "secure"
            })
          }), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "Boolean"
            })
          }), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "false"
            })
          }), _jsx(_components.td, {})]
        }), _jsxs(_components.tr, {
          children: [_jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "sameSite"
            })
          }), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "Enum"
            })
          }), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "lax"
            })
          }), _jsxs(_components.td, {
            children: ["Can also be ", _jsx(_components.code, {
              children: "strict"
            }), " or ", _jsx(_components.code, {
              children: "none"
            })]
          })]
        })]
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  return _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  });
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
